import { Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { useAuthContext } from 'src/auth/hooks';
import Logo from 'src/components/logo';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { bgBlur } from 'src/theme/css';
import { HeaderShadow, LanguagePopover } from '../_common';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavDesktop from './nav/desktop';
import NavMobile from './nav/mobile';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const { t } = useLocales();
  const { authenticated } = useAuthContext();

  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },

          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Logo
            type={mdUp ? 'full' : 'single'}
            sx={{
              mr: 4,
            }}
          />

          {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

          <Box sx={{ flexGrow: 1 }} />

          <Stack alignItems="center" direction={{ xs: 'row', md: 'row' }}>
            <LanguagePopover />

            <Button
              variant="outlined"
              onClick={() => {
                router.push(paths.auth.login);
              }}
            >
              {authenticated
                ? t('walkup_lang_256')?.toUpperCase()
                : t('walkup_lang_135')?.toUpperCase()}
            </Button>

            {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
