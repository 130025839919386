import { paths } from 'src/routes/paths';

export const navConfig = [
  // inventory
  {
    title: 'walkup_lang_067',
    icon: 'ph:sneaker-duotone',
    path: paths.dashboard.inventory.root,
  },
  // store
  {
    title: 'walkup_lang_256',
    icon: 'solar:cart-large-bold-duotone',
    path: paths.dashboard.store.root,
  },
  // wallet
  {
    title: 'walkup_lang_069',
    icon: 'ph:wallet-duotone',
    path: paths.dashboard.wallet.root,
  },
  // profile
  {
    title: 'walkup_lang_070',
    icon: 'lets-icons:user-duotone',
    path: paths.dashboard.profile.root,
  },
  // staking
  {
    title: 'walkup_lang_225',
    icon: 'solar:money-bag-bold-duotone',
    path: paths.dashboard.staking.root,
  },
];
