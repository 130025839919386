import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hooks';
//
import Footer from './footer';
import Header from './header';

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  const pathname = usePathname();

  console.log(pathname);

  const isHome = pathname === '/';
  const isMapPage = pathname.includes('/maps');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      {!isMapPage && <Header />}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome &&
            !isMapPage && {
              pt: { xs: 8, md: 10 },
            }),
        }}
      >
        {children}
      </Box>

      {!isMapPage && <Footer />}
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
