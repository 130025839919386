import { IconButton, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Iconify from 'src/components/iconify';
import Logo from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';
import systemConfig from 'src/config/system-config';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'About Walkup',
    children: [
      { name: 'Move to Earn', href: '/' },
      { name: 'Play to Earn', href: '#' },
      { name: 'E-commerce Mall', href: '#' },
      { name: 'Partner in F&B Sector', href: '#' },
      { name: 'VISA Mastercard Payment and Cashback', href: '#' },
      { name: 'Socialfi', href: '#' },
      { name: 'Wallet & Defi Exchange', href: '#' },
      { name: 'AI Trading Bot', href: '#' },
    ],
  },
  {
    headline: 'Join Ecosystem',
    children: [
      { name: 'FAQs', href: paths.faqs },
      { name: 'Whitepaper', href: systemConfig.files.whitepaper.en, isExternalLink: true },
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'support@walkup.world', href: '#' }],
  },
];

const SOCIALS = [
  {
    key: 'telegram',
    label: 'Telegram - Group',
    icon: 'mingcute:telegram-fill',
    color: '#fff',
    link: 'https://t.me/walkupglobal',
  },
  {
    key: 'telegram',
    label: 'Telegram - Channel',
    icon: 'mingcute:telegram-fill',
    color: '#fff',
    link: ' https://t.me/walkupannouncement',
  },
  {
    key: 'twitter',
    label: 'Twitter',
    icon: 'mdi:twitter',
    color: '#1DA1F2',
    link: 'https://twitter.com/walkupglobal',
  },
  {
    key: 'kakao',
    label: 'Kakao',
    icon: 'mingcute:kakao-talk-fill',
    color: '#1DA1F2',
    link: 'https://open.kakao.com/o/gKzv2hZf',
  },
  {
    key: 'discord',
    label: 'Discord',
    icon: 'ic:baseline-discord',
    color: '#1DA1F2',
    link: 'https://discord.gg/walkup',
  },
  {
    key: 'certik',
    label: 'Certik',
    img: '/assets/icons/app/ic_certik.png',
    color: '#1DA1F2',
    link: 'https://skynet.certik.com/projects/walkup',
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const settings = useSettingsContext();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo type="full" sx={{ mb: 3 }} onClick={scrollToTop} />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={12} md={5} mb={{ xs: 3, md: 0 }}>
            <Typography>
              WALK UP is a web3 fitness and lifestyle app that incorporates NFT gaming and Social-Fi
              elements. Users have the opportunity to earn rewards in tokens or NFTs simply by
              engaging in physical activities like walking, jogging, and running.
            </Typography>

            <Stack alignItems={{ xs: 'center', md: 'flex-start' }} mt={2}>
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Iconify icon="iconamoon:mode-light" />
                <Switch
                  checked={settings.themeMode === 'dark'}
                  onChange={(e, checked) => {
                    settings.onUpdate('themeMode', checked ? 'dark' : 'light');
                  }}
                />
                <Iconify icon="ic:round-dark-mode" />
              </Stack>
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => {
                    if (link.isExternalLink)
                      return (
                        <Link
                          key={link.name}
                          component={RouterLink}
                          href={link.href}
                          target="_blank"
                          color="inherit"
                          variant="body2"
                        >
                          {link.name}
                        </Link>
                      );

                    return (
                      <Link
                        key={link.name}
                        component={RouterLink}
                        href={link.href}
                        color="inherit"
                        variant="body2"
                      >
                        {link.name}
                      </Link>
                    );
                  })}

                  {list.headline === 'Contact' && (
                    <Stack>
                      <Typography component="div" variant="overline">
                        Social
                      </Typography>

                      <Stack direction="row" alignItems="center" spacing={1} mt={2}>
                        {SOCIALS.map((social) => (
                          <IconButton
                            key={social.key}
                            sx={{
                              borderRadius: '10px',
                              background: (theme) => theme.palette.grey[800],
                              '&:hover': {
                                background: (theme) => theme.palette.grey[800],
                              },
                            }}
                            onClick={() => {
                              window.open(social.link);
                            }}
                          >
                            {social.icon && <Iconify icon={social.icon} width={30} color="#fff" />}
                            {social.img && <img src={social.img} alt={social.key} width={30} />}
                          </IconButton>
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" align="center" color="text.secondary" sx={{ mt: 10 }}>
          Copyright 2020 WALK UP. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}
