import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { HEADER } from '../config-layout';

// ----------------------------------------------------------------------

export default function Main({ children, sx, ...other }) {
  return (
    <Box
      component="main"
      sx={{
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: `${HEADER.H_DESKTOP_OFFSET * 1.5}px`,
        pb: 10,
      }}
    >
      {children}
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
