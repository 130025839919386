import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, type = 'single', sx, ...other }, ref) => {
  const settings = useSettingsContext();

  const logo = (
    <Box
      component="img"
      src={`/logo/logo_${settings.themeMode === 'dark' ? 'light' : 'dark'}_${type}.png`}
      sx={{ height: 40, cursor: disabledLink ? 'unset' : 'pointer', ...sx }}
      {...other}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  type: PropTypes.string,
  sx: PropTypes.object,
};

export default Logo;
