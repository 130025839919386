import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '$0';

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fAmount(number, fix) {
  try {
    let str = `${number}`;
    if (str.indexOf('e') >= 0) {
      str = `${number.toFixed(8)}`;
    }
    const deleteText = str.replace(/[^\d.]/g, '');
    const x = deleteText.split('.');
    let x1 = x[0];
    const x2 = x[1];
    const x3 = x.length > 1 ? `.${x2.slice(0, fix || 8)}` : '';
    if (!x1) x1 = '0';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1,$2');
    }
    const res = (x1 + x3).replace(/^0+(?!\.|$)/, '').replace(/^\./, '');
    return `${number < 0 ? '-' : ''}${res}`;
  } catch (e) {
    return '0.00';
  }
}

export const fPhoneNumber = (number) => {
  const formattedNumber = `${number.slice(0, 4)} ${number.slice(4, 7)} ${number.slice(7)}`;
  return formattedNumber;
};
