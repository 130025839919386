import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'HOME',
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: '/',
  },
  {
    title: 'TOKEN',
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: paths.token.root,
  },
  {
    title: 'ECOSYSTEM',
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: paths.ecosystem.root,
  },
  {
    title: 'GUIDE',
    path: '/guide',
    icon: <Iconify icon="solar:file-bold-duotone" />,
    children: [
      {
        items: [
          {
            title: 'IOS',
            path: {
              en: 'https://walk-up.gitbook.io/walk-up-app-guideline-ios-1/for-ios',
              ko: 'https://walk-up.gitbook.io/walk-up-app-guideline-ios/undefined',
              ja: 'https://walk-up.gitbook.io/walk-up-app-guideline-ios-2/ios-ban',
            },
          },
          {
            title: 'Android',
            path: {
              en: 'https://walk-up.gitbook.io/walk-up-app-guideline-android-1/',
              ko: 'https://walk-up.gitbook.io/walk-up-app-guideline-android/undefined',
              ja: 'https://walk-up.gitbook.io/walk-up-app-guideline-android-2/andoroido',
            },
          },
          {
            title: 'walkup_lang_226',
            path: {
              en: 'https://walk-up.gitbook.io/lbank-guideline',
              ko: 'https://walk-up.gitbook.io/lbank-guideline',
              ja: 'https://walk-up.gitbook.io/lbank-guideline',
            },
          },
        ],
      },
    ],
  },
  {
    title: 'WHITEPAPER',
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: {
      en: 'https://whitepaper.walkup.world',
      ko: 'https://whitepaper-kr.walkup.world',
      ja: 'https://whitepaper-jp.walkup.world',
    },
  },
];
