import { Switch } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { useDisconnect, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { m } from 'framer-motion';
import { useAuthContext } from 'src/auth/hooks';
import { varHover } from 'src/components/animate';
import { Copy } from 'src/components/copy';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { useSnackbar } from 'src/components/snackbar';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'walkup_lang_070',
    linkTo: paths.dashboard.profile.root,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();
  const { disconnect } = useDisconnect();
  const { isConnected } = useWeb3ModalAccount();
  const settings = useSettingsContext();
  const { t } = useLocales();

  const { user } = useAuthContext();

  const { logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      if (isConnected) disconnect();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar src={user?.info?.avatar}>W</Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Copy text={user?.id}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              Runner {user?.id}
            </Typography>
          </Copy>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack direction="row" alignItems="center" justifyContent="center">
          <Iconify icon="iconamoon:mode-light" />
          <Switch
            checked={settings.themeMode === 'dark'}
            onChange={(e, checked) => {
              settings.onUpdate('themeMode', checked ? 'dark' : 'light');
            }}
          />
          <Iconify icon="ic:round-dark-mode" />
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}>
          {t('walkup_lang_129')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
