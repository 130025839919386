import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DevGuard from 'src/auth/guard/dev-guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard';

// ----------------------------------------------------------------------

const WalletPage = lazy(() => import('src/pages/wallet'));

const InventoryPage = lazy(() => import('src/pages/inventory'));

const StorePage = lazy(() => import('src/pages/store'));

const MarketplacePage = lazy(() => import('src/pages/marketplace'));

const ProfilePage = lazy(() => import('src/pages/profile'));

const VoucherPage = lazy(() => import('src/pages/voucher'));

const PromotionWebview = lazy(() => import('src/pages/webview/promotion'));

const SupportPage = lazy(() => import('src/pages/support'));

const StakingPage = lazy(() => import('src/pages/staking'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'inventory', element: <InventoryPage /> },
      { path: 'shop', element: <StorePage /> },
      { path: 'voucher', element: <VoucherPage /> },
      {
        path: 'open-marketplace',
        element: (
          <DevGuard>
            <MarketplacePage />
          </DevGuard>
        ),
      },
      { path: 'wallet', element: <WalletPage /> },
      { path: 'profile', element: <ProfilePage /> },

      { path: 'support', element: <SupportPage /> },
      { path: 'staking', element: <StakingPage /> },
    ],
  },

  {
    path: 'webview',
    element: <Outlet />,
    children: [{ path: 'promotion/:accessToken', element: <PromotionWebview /> }],
  },
];
