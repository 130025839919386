import { BottomNavigation, BottomNavigationAction, Paper, Typography } from '@mui/material';
import { useLocation } from 'react-router';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { navConfig } from './config-navigation';

function BottomTab() {
  const router = useRouter();
  const { pathname } = useLocation();
  const { t } = useLocales();

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={pathname}
        onChange={(event, newValue) => {
          router.push(newValue);
        }}
      >
        {navConfig.map((item) => (
          <BottomNavigationAction
            key={item.path}
            label={
              <Typography variant="subtitle2" textTransform="capitalize">
                {t(item.title)}
              </Typography>
            }
            icon={<Iconify icon={item.icon} />}
            value={item.path}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}

export default BottomTab;
