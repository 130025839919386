import PropTypes from 'prop-types';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import BottomTab from './bottom-tab';
import Header from './header';
import Main from './main';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const nav = useBoolean();

  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Main>
        {children}

        {!mdUp && <BottomTab />}
      </Main>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
