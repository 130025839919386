import { IconButton, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useLocation } from 'react-router';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import Logo from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useRouter } from 'src/routes/hooks';
import { bgBlur } from 'src/theme/css';
import { AccountPopover, LanguagePopover } from '../_common';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavDesktop from './nav/desktop/nav-desktop';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  const { user } = useAuthContext();
  const router = useRouter();

  const settings = useSettingsContext();

  const { isConnected, address } = useWeb3ModalAccount();

  const location = useLocation();
  const { pathname } = location;

  const mdUp = useResponsive('up', 'md');

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const renderContent = (
    <>
      <Logo type={mdUp ? 'full' : 'single'} sx={{ mr: 2.5 }} />

      {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* {pathname !== paths.dashboard.profile.root ? (
          <ConnectWallet />
        ) : (
          isConnected && user?.address && address === user?.address && <ConnectWallet />
        )} */}

        <Tooltip title="Support">
          <IconButton onClick={() => router.push('/support')}>
            <Iconify icon="streamline:customer-support-1" />
          </IconButton>
        </Tooltip>

        <LanguagePopover />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        width: 1,
        height: HEADER.H_DESKTOP,

        bgcolor: 'background.default',
        borderBottom: `dashed 1px ${theme.palette.divider}`,

        zIndex: theme.zIndex.appBar + 1,

        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
