import PropTypes from 'prop-types';
// @mui
import Collapse from '@mui/material/Collapse';
import { listClasses } from '@mui/material/List';
import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { listItemTextClasses } from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { NavSectionVertical } from 'src/components/nav-section';
//
import { useLocales } from 'src/locales';
import NavItem from './nav-item';

// ----------------------------------------------------------------------

export default function NavList({ item, active, itemOnClick }) {
  const { path, children } = item;
  const { currentLang } = useLocales();

  const externalLink =
    typeof path === 'string' ? path.includes('http') : path[currentLang.value].includes('http');

  const nav = useBoolean();

  return (
    <>
      <NavItem
        item={item}
        open={nav.value}
        onClick={() => {
          nav.onToggle();
          if (itemOnClick) itemOnClick();
        }}
        active={active}
        externalLink={externalLink}
      />

      {!!children && (
        <Collapse in={nav.value} unmountOnExit>
          <NavSectionVertical
            data={children}
            // sx={{
            //   [`& .${listClasses.root}`]: {
            //     '&:last-of-type': {
            //       [`& .${listItemButtonClasses.root}`]: {
            //         height: 160,
            //         backgroundSize: 'cover',
            //         backgroundPosition: 'center',
            //         bgcolor: 'background.neutral',
            //         backgroundRepeat: 'no-repeat',
            //         backgroundImage: 'url(/assets/illustrations/illustration_dashboard.png)',
            //         [`& .${listItemTextClasses.root}`]: {
            //           display: 'none',
            //         },
            //       },
            //     },
            //   },
            // }}
          />
        </Collapse>
      )}
    </>
  );
}

NavList.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
  itemOnClick: PropTypes.func,
};
