import { enUS as enUSCore, jaJP as jaJPCore, koKR as koKRCore } from '@mui/material/locale';
import { enUS as enUSDataGrid, jaJP as jaJPDataGrid, koKR as koKRDataGrid } from '@mui/x-data-grid';
import { enUS as enUSDate, jaJP as jaJPDate, koKR as koKRDate } from '@mui/x-date-pickers/locales';
import { enUS as enUSAdapter, ja as jaJPAdapter, ko as koKRAdapter } from 'date-fns/locale';
import merge from 'lodash/merge';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:us',
  },
  {
    label: 'Korean',
    value: 'ko',
    systemValue: merge(koKRDate, koKRDataGrid, koKRCore),
    adapterLocale: koKRAdapter,
    icon: 'flagpack:kr',
  },
  {
    label: 'Japan',
    value: 'ja',
    systemValue: merge(jaJPDate, jaJPDataGrid, jaJPCore),
    adapterLocale: jaJPAdapter,
    icon: 'flagpack:jp',
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
